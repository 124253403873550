/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 24/10/2021.
 */
import { Button, Result } from 'antd'

import React from 'react'

type Props = {
  error: any
}

//
// This is work in prigress - we need a way to handle specific errors for specific endpoints
//

export const ErrorView = ({ error }: Props) => {
  //  const logout = authStore((state) => state.logout)
  console.log(' ErrorView > error = ', error)

  const message: string = error?.message ? error?.message : 'Something went wrong'
  const status: number = error?.status ? error?.status : 500

  return (
    <div className="container">
      <div className="row" style={{}}>
        <div className="col-6 mx-auto">
          <div className="mt-4 shadow bg-white py-5 rounded">
            {message && status ? (
              <ErrorResult status={status} title={status} subTitle={error.message}></ErrorResult>
            ) : (
              <ErrorResult
                status="500"
                title="500"
                subTitle="This was not expected and could be caused by multiple things"
              ></ErrorResult>
            )}
            <div className="d-flex justify-content-center mt-2 ">
              <Button type="primary">
                <a className="text-decoration-none" href="/">
                  Back to the frontpage
                </a>
              </Button>
            </div>
            <div className="text-center text-decoration-none mt-4">
              <a href="mailto:dennis.christoffersen@studiesandme.com" style={{ color: 'rgba(0,0,0,.5)' }}>
                Contact Technical support
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ErrorResult = ({ status, title, subTitle }: any) => {
  const supported = [500, 403, 404]
  const supportedStatus = supported.indexOf(status) === -1 ? 'warning' : status
  console.log(' ErrorView > supportedStatus = ', supportedStatus)
  return (
    <Result
      className="my-0 py-1"
      status={supportedStatus}
      title={title}
      subTitle={
        <p className="py-2 " style={{ overflowWrap: 'break-word', color: '#9c9c9c', fontSize: '1rem' }}>
          {subTitle}
        </p>
      }
    />
  )
}
