import { UserSwitchOutlined } from '@ant-design/icons'
import { Button, Modal, Popconfirm, Radio, RadioChangeEvent } from 'antd'
import { SetStateAction, useState } from 'react'

import { mutate as mutateGlobal } from 'swr'
import { BODY_PARTS } from '../../../constants'
import { post } from '../../../service/API'
import { ENDPOINTS } from '../../../service/ENDPOINTS'
import { useEligibleAssignments } from '../../../hooks/useEligibleAssignments'
import { useParams } from 'react-router-dom'
import { DataType } from '../SubjectEligibilityView'
import { useEligibility } from '../../../hooks/useEligibility'
import { EligibilityTable } from '../eligibility/EligibilityTable'

type MoveProps = {
  isModalOpen: boolean
  setIsMoveModalOpen: (e: SetStateAction<boolean>) => void
  subjectData: any
  nextPhase: any
  mutateSubjectData: any
}

export const MoveModal = ({
  isModalOpen,
  setIsMoveModalOpen,
  subjectData,
  nextPhase,
  mutateSubjectData,
}: MoveProps) => {
  const { projectId, subjectId } = useParams() as { projectId: string; subjectId: string }

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [openPop, setOpenPop] = useState(false)
  const [selectedBodyParts, setSelectedBodyParts] = useState<string>()

  const [showEligibility, setShowEligibility] = useState(false)
  const [eligibilityData, setEligibilityData] = useState(undefined)

  const eligibleAssignments = useEligibleAssignments(projectId, subjectId)

  const preventCall = eligibleAssignments.data === true ? false : true

  const {
    data,
    mutate: mutateEligibility,
    isError,
  }: { data: DataType; mutate: () => void; isError: any; isLoading: boolean } = useEligibility(
    projectId,
    subjectId,
    preventCall
  )

  const showPopconfirm = () => {
    setOpenPop(true)
  }

  const confirm = () => {
    setConfirmLoading(true)

    const projectId = subjectData.projectId._id

    const p = eligibilityData
      ? ENDPOINTS.SUBJECTS.ACTION.moveEligibility(projectId)
      : ENDPOINTS.SUBJECTS.ACTION.move(projectId)

    const obj = {
      subjectId: subjectData._id,
      bodyPart: selectedBodyParts,
      projectId: projectId,
    }

    return post(p, obj, 'PUT')
      .then(() => {
        mutateSubjectData()
        setIsMoveModalOpen(false)
        setConfirmLoading(false)
        setSelectedBodyParts(undefined)
        setOpenPop(false)
        setShowEligibility(false)
        mutateGlobal(ENDPOINTS.SUBJECTS.getSubjectAudit(projectId, subjectData._id))
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  const hideModal = () => {
    setIsMoveModalOpen(false)
    setOpenPop(false)
    setSelectedBodyParts(undefined)
    setEligibilityData(undefined)
    setShowEligibility(false)
  }

  const handleMoveOk = () => {
    setIsMoveModalOpen(false)
    setOpenPop(false)
  }

  const handleMoveCancel = () => {
    setIsMoveModalOpen(false)
    setOpenPop(false)
    setSelectedBodyParts(undefined)
    setEligibilityData(undefined)
    setShowEligibility(false)
  }

  const cancel = () => {
    setOpenPop(false)
  }

  const onChange = (e: RadioChangeEvent) => {
    setSelectedBodyParts(e.target.value)
  }

  const showEligibilityTable = () => {
    setShowEligibility(true)
  }

  const plainOptions = nextPhase.bodyParts
    ? nextPhase.bodyParts.map((item) => {
        return { bodyPart: item, label: BODY_PARTS[item] || item }
      })
    : []

  const canMove = nextPhase.bodyParts
    ? (nextPhase.bodyParts.length > 0 && selectedBodyParts !== undefined) ||
      (nextPhase.bodyParts.length === 0 && selectedBodyParts !== undefined)
    : false

  return (
    <>
      <Modal
        title={[
          <div key={1}>
            <UserSwitchOutlined className="me-2" style={{ fontSize: '22px' }} />
            Move subject to the next Phase - {nextPhase.title}
          </div>,
        ]}
        open={isModalOpen}
        onOk={handleMoveOk}
        onCancel={handleMoveCancel}
        destroyOnClose={true}
        width={650}
        footer={[
          <div key={2}>
            <Button onClick={hideModal} className="me-2">
              Cancel
            </Button>
            <Popconfirm
              title={'Confirmation: Move the subject to the next phase - ' + nextPhase.title}
              onConfirm={confirm}
              open={openPop}
              onCancel={cancel}
              okButtonProps={{ loading: confirmLoading }}
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button type="primary" onClick={showPopconfirm} disabled={plainOptions.length > 0 ? !canMove : false}>
                Move subject to next phase - {nextPhase.title}
              </Button>
            </Popconfirm>
          </div>,
        ]}
      >
        <div className="container ">
          {!showEligibility && eligibleAssignments.data ? (
            <div className="row mt-3" style={{}}>
              <div
                className="col-12 px-4 d-flex justify-content-between  align-items-center rounded-2 py-3"
                style={{ background: '#e7f3ff' }}
              >
                <div className="">
                  <p className="mb-0 mt-1 p-small" style={{ color: '#346ca8' }}>
                    Eligibility calculation is availble for the current phase
                  </p>
                </div>
                <div className="">
                  <Button onClick={showEligibilityTable} className="me-2">
                    Show Eligibility
                  </Button>
                </div>
              </div>
            </div>
          ) : null}

          {showEligibility ? (
            <>
              {isError || !data ? (
                <ErrorFallback error={isError} onClose={isModalOpen} />
              ) : (
                <EligibilityTable data={data} mutateEligibility={mutateEligibility} />
              )}
            </>
          ) : null}

          <div className="col-12">
            <div className="row">
              <p className="mt-3">
                {nextPhase?.bodyParts?.length > 0
                  ? 'You are about to move the subject to the next phase of the study. Please select the target body part for the subject:'
                  : 'You are about to move the subject to the next phase of the study.'}
              </p>

              <div className="mt-3">
                <Radio.Group onChange={onChange}>
                  {plainOptions.map((option, index) => {
                    return (
                      <Radio value={option ? option.bodyPart : ''} key={index}>
                        {option.label ? option.label : ''}
                      </Radio>
                    )
                  })}
                </Radio.Group>
              </div>
              {nextPhase?.bodyParts?.length > 0 ? (
                <p className="mt-3">
                  Note: During the next phase, the subject will only report pain for the selected body part.
                </p>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
  return (
    <div className="container-fluid">
      <div role="alert" className="row">
        <div className="col-12 p-4" style={{ background: '#f4f4f4' }}>
          <h4>Something went wrong</h4>

          <p>{error.message}</p>
        </div>
      </div>
    </div>
  )
}
