/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 12/04/2023.
 */
import { Route, Routes, useParams } from 'react-router-dom'
import React, { Suspense } from 'react'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useProtocol } from '../../hooks/useProtocol'
import { ProjectPhaseListItem } from './ProjectPhaseListItem'
import { ProjectQuestionnaireDrawer } from './ProjectQuestionnaireDrawer'
import { useSubjectAttention } from '../../hooks/useSubjectAttention'
import { AttendToSubjectsList } from '../../components/AttendToSubjectsList'
import { useProjectById } from '../../hooks/useProjectById'
import { PageHeader } from '../../components/display/PageHeader'

export const ProjectView = () => {
  const { projectId } = useParams() as { projectId: string }

  const { data } = useProjectById(projectId)
  const { data: protocol } = useProtocol(projectId)
  const { data: subjects } = useSubjectAttention(projectId, null)

  const attendToSubjects = subjects?.subjects || []
  const nonCompliant = subjects?.nonCompliant || []

  const closeDrawer = () => {}

  return (
    <>
      <Routes>
        <Route
          path="/questionnaire/:questionnaireId"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <DrawerContainer
                projectId={projectId}
                setVisible={closeDrawer}
                width={500}
                to="../"
                title="View questionnaire"
              >
                <ProjectQuestionnaireDrawer />
              </DrawerContainer>
            </Suspense>
          }
        />
      </Routes>

      <>
        <div
          className="container-fluid bg-white"
          style={{
            borderBottom: '1px solid rgba(0,0,0,.1)',
            boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.04)',
          }}
        >
          <div className="container px-lg-6 mb-3" style={{}}>
            <PageHeader
              title="Study Overview"
              body="Explore the study progress overview, highlighting non-compliant subjects and their reasons. For
                  in-depth details, simply scroll down to delve into the study specifications."
            ></PageHeader>
          </div>
        </div>

        <div className="container mt-3 px-4 mb-7">
          <AttendToSubjectsList
            attendToSubjects={attendToSubjects}
            nonCompliant={nonCompliant}
            project={data}
          ></AttendToSubjectsList>
          <div className="row" style={{}}>
            <div className="col-12 mt-2 py-3 mt-4">
              <h6>Phases</h6>
            </div>

            {protocol && protocol.phases.map((phase, index) => <ProjectPhaseListItem key={index} phase={phase} />)}
          </div>
        </div>
      </>

      {/*
        <div className="row" style={{}}>
          <div className="col-12 my-6">
            <div
              className="h-100 px-4 py-4 rounded-1  bg-gradient"
              style={{
                boxShadow: '0px 3px 4px -3px rgba(0, 128, 0, .4)',
                //border: '1px solid rgb(35, 126, 240, .5)',
                border: '1px solid rgb(0, 128, 0, .3)',
                background: '#E7FFEE',
              }}
            >
              <h5 style={{ color: '#008000' }}>Need help</h5>
              <p className="p-small w-75 " style={{ color: '#008000' }}>
                For urgent matters please call Christoffer{' '}
                <span className="me-1 opacity-75">
                  <i>24 25 70 85</i>
                </span>
                or Andras
                <span className="ms-1 opacity-75">
                  <i>22 63 62 81</i>
                </span>
                .
                <br />
                Get in touch by email:{' '}
                <a style={{ color: '#008000' }} href="mailto:epro.support@studiesandme.com">
                  epro.support@studiesandme.com
                </a>
              </p>
            </div>
          </div>
        </div>
*/}

      {/*Temporary hidden - START */}
      {/*<div className="row mb-3">*/}
      {/*  <div className="col-12 mt-3 py-3">*/}
      {/*    <h6>Another thing</h6>*/}
      {/*  </div>*/}

      {/*  <div className="col-12 ">*/}
      {/*    <div*/}
      {/*      className="h-100 px-4 py-5 rounded-1  bg-gradient"*/}
      {/*      style={{*/}
      {/*        boxShadow: '0px 3px 4px -3px rgba(101, 99, 95, .4)',*/}
      {/*        //border: '1px solid rgb(35, 126, 240, .5)',*/}
      {/*        border: '1px solid rgb(164, 163, 155, .6)',*/}
      {/*        background: '#fffaf1',*/}
      {/*      }}*/}
      {/*    >*/}
      {/*      <h5 style={{ color: '#5d5b58' }}>Notifications</h5>*/}
      {/*      <p className="p-small w-75 " style={{ color: '#5d5b58' }}>*/}
      {/*        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam commodi, dignissimos et harum id iure*/}
      {/*      </p>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*Temporary hidden - END */}
    </>
  )
}
