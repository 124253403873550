import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const useEligibleAssignments = (projectId: string, subjectId: string) => {
  const p = ENDPOINTS.SUBJECTS.eligibleAssignments(projectId, subjectId)

  const { data, error, mutate } = useSWR(p, getReq, { suspense: true })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
