/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 11/09/2023.
 */
import React, { useState } from 'react'
import { FormTextField } from '../../components/form/FormTextField'
import { COUNTRY_TYPE, CRUD_VIEW_TYPE } from '../../Types'
import { Button } from 'antd'
import { FormPhoneNumber } from '../../components/form/FormPhoneNumber'
import { isValidEmail } from '../../util/isValidEmail'
import { useParams } from 'react-router-dom'
import { useProjectById } from '../../hooks/useProjectById'
import { FormDropDown } from '../../components/form/FormDropDown'
import { useSite } from '../../hooks/useSite'
import { LANGUAGE_LABELS } from '../../constants'

type Props = {
  formHook: any
  nextIndex: number
  prevIndex: number
  setPageIndex: (index: number) => void
}

const nameConfig = {
  id: 'name',
  type: 'TEXT',
  placeholder: "Subject's name",
  label: 'Name',
  prefix: '',
  postfix: '',
  value: '',
  validation: { required: true },
  errorMsg: 'this is required',
  defaultValue: '',
  disabled: false,
  className: '',
}
const emailConfig = {
  id: 'email',
  type: 'TEXT',
  placeholder: "Subject's email",
  label: 'Email',
  prefix: '',
  postfix: '',
  value: '',
  validation: { required: 'Please enter an email' },
  errorMsg: 'Please enter an email ',
  defaultValue: '',
  disabled: true,
  className: '',
}

const phoneNumberConfig = {
  id: 'phone',
  type: 'PHONE',
  placeholder: 'Add investigator phone number',
  label: 'Phone number',
  prefix: '',
  postfix: '',
  value: '',
  validation: {
    required: false,
    validate: (value) => {
      /*if (!value.tel && !value.countryCode) {
        return 'This is required.'
      }*/
      //console.log('UserPii > value = ', value)
      // if (!value.tel) {
      //   return 'Phone number is required.'
      // }
      // if (!value.countryCode) {
      //   return 'Country code is required.'
      // }

      return value || 'This is required.'
    },
  },
  errorMsg: 'Phone number required',
  disabled: true,
}

const generateLanguageDropdown = (data) => {
  const languageOptions = data
    .map((lang) => {
      return { value: lang.isoCode.toUpperCase(), label: LANGUAGE_LABELS[lang.isoCode], active: lang.active }
    })
    .filter((lang) => lang.active)
  return {
    id: 'language',
    type: 'DROPDOWN',
    placeholder: 'Select preferred language',
    label: languageOptions.length > 0 ? "Subject's language" : "Subject's language (Currently no languages active)",
    prefix: '',
    postfix: '',
    value: '',
    options: languageOptions,
    validation: { required: true },
    errorMsg: 'A language must be selected',
    disabled: true,
  }
}

export const UserPii = ({ formHook, prevIndex, nextIndex, setPageIndex }: Props) => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const { data } = useProjectById(projectId)

  const { data: siteData } = useSite(projectId, siteId)

  /**
   * Find which languages are matching on both the project and site
   *
   * We cannot create subjects with languages that are not matching
   *
   * .find() is not supported in IE11, below is a different version
   * - https://caniuse.com/array-find
   */
  const activeInBoth = data.languages.filter((lang) => {
    const matchingSiteLang = siteData.languages.find((siteLang) => siteLang.isoCode === lang.isoCode)
    return matchingSiteLang && lang.active && matchingSiteLang.active
  })

  /**
   * Supported version for IE 11
   */
  // const activeInBoth = data.languages.filter((lang) => {
  //   let foundMatchingSiteLang = false
  //   for (let i = 0; i < siteData.languages.length; i++) {
  //     const siteLang = siteData.languages[i]
  //     if (siteLang.isoCode === lang.isoCode) {
  //       foundMatchingSiteLang = true
  //       if (lang.active && siteLang.active) {
  //         return true
  //       }
  //     }
  //   }
  //   return false
  // })

  // Contains languages active on the project
  const langConfig = data && siteData ? generateLanguageDropdown(activeInBoth) : generateLanguageDropdown([])

  const supportedCountries: COUNTRY_TYPE[] = data.country.map((item) => {
    return {
      abbreviation: item.abbreviation,
      name: item.name,
      countryCode: item.code,
    }
  })

  console.log('UserPii > langConfig > ', langConfig)

  const { getValues, trigger, setError } = formHook
  const [onePii, setOnePii] = useState<string>('')

  const nextClick = () => {
    next()
  }

  const acceptRiskClick = () => {
    next(true)
  }

  const next = async (override: boolean = false) => {
    const isValidArr = await Promise.all([
      trigger(nameConfig.id),
      trigger(emailConfig.id),
      trigger(phoneNumberConfig.id),
      trigger(langConfig.id),
    ])

    const phoneObj = getValues(phoneNumberConfig.id)

    const isValidPhone = phoneObj.countryCode && phoneObj.tel ? true : false

    const mayBeEmail = getValues(emailConfig.id)
    const validateEmail = isValidEmail(mayBeEmail)

    const validObj = { email: validateEmail, phone: isValidArr[2], name: isValidArr[0], language: isValidArr[3] }

    // has email but email is not valid
    if (isValidArr[1] === true && !validateEmail) {
      setError(emailConfig.id, { type: 'custom', message: 'It does not look like a valid email' })
      return
    }

    // has phone but phone is not valid
    if (isValidArr[0] === false) {
      //setError(langConfig.id, { type: 'custom', message: 'It does not look like a valid email' })
      return
    }

    // has selected language
    if (isValidArr[3] === false) {
      //setError(langConfig.id, { type: 'custom', message: 'It does not look like a valid email' })
      return
    }

    // continue with phone number
    if (validObj.email === false && isValidPhone === true) {
      setOnePii('phone')
    }

    if (validObj.email === true && isValidPhone === false) {
      setOnePii('email')
    }

    /*if (validObj.language validObj.phone === false) {
      setOnePii('email')
    }*/

    // all is set
    if (validObj.email === true && isValidPhone === true && validObj.name === true) {
      setPageIndex(nextIndex)
      return
    }

    if ((validateEmail === true || isValidPhone === true) && override === true && validObj.name === true) {
      //console.log('UserPii > go go go go = ')
      setPageIndex(nextIndex)
    }
  }

  const prev = () => {
    console.log('UserPii > what = ', nextIndex)
    setPageIndex(prevIndex)
  }

  return (
    <div className="row ">
      <div className="col-6 mx-auto mt-6 ">
        <div className="bg-white border block-shadow p-5">
          <h5 className="">Please enter subject contact info</h5>
          <FormTextField data={nameConfig} formHook={formHook} viewType={CRUD_VIEW_TYPE.UPDATE}></FormTextField>
          <FormTextField data={emailConfig} formHook={formHook} viewType={CRUD_VIEW_TYPE.UPDATE}></FormTextField>
          <FormDropDown data={langConfig} formHook={formHook}></FormDropDown>
          <FormPhoneNumber
            data={phoneNumberConfig}
            formHook={formHook}
            viewType={CRUD_VIEW_TYPE.CREATE}
            supportedCountries={supportedCountries}
          ></FormPhoneNumber>

          {onePii ? (
            <div className="border mt-2 p-4 position-relative " style={{ background: '#dcdcdc' }}>
              <div
                onClick={() => {
                  setOnePii('')
                }}
                className="position-absolute btn-close pointer p-3"
                style={{ right: 0, top: 0 }}
              ></div>
              <p className="fw-bold mb-2">Subject Contact Details Missing:</p>
              <p>You have only provided one contact detail for the subject.</p>
              <p className="mt-2">
                Before proceeding, please confirm if you would like to add an additional contact detail.
              </p>
              <p className="mt-2">
                It's important to note that the email address provided may be utilized for PIN reset purposes on the
                subject's tablet.
              </p>

              <div className="d-flex justify-content-end">
                <Button className="mt-3" type="primary" danger={true} onClick={acceptRiskClick}>
                  Accept risk and continue
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="col-12 d-flex justify-content-center mt-4">
        <Button className="mx-1" type="default" onClick={prev}>
          Back
        </Button>
        <Button className="mx-1" type="primary" onClick={nextClick}>
          Continue
        </Button>
      </div>
    </div>
  )
}
