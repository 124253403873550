/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 25/04/2023.
 */
import { Badge, Button, Empty, Tooltip } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { SearchClinician } from '../../components/SearchClinician'
import { useCliniciansByProjectId } from '../../hooks/useCliniciansByProjectId'

export const ClinicianView = () => {
  const { projectId } = useParams() as { projectId: string }
  const { data } = useCliniciansByProjectId(projectId)

  const assigned = data.assigned
  const notAssigned = data.notAssigned

  return (
    <div className="container-fluid">
      <div className="row" style={{}}>
        <div className="col-12 ps-0 mb-3">
          <SearchClinician data={assigned}></SearchClinician>
        </div>
      </div>
      <div className="row" style={{}}>
        {assigned.length > 0 ? (
          assigned.map((item, index) => {
            /*const siteLinkArr = item.mysites.map((siteObj) => {
              return { to: `sites/${siteObj._id}`, siteNumber: siteObj.siteNumber }
            })*/

            const siteArr = item.mysites.map((siteObj) => {
              return siteObj.siteNumber
            })

            return (
              <div
                key={index}
                className="col-12 bg-white  d-flex align-items-center border-bottom"
                style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)' }}
              >
                <div className="ps-2 my-3 " style={{ width: '30%' }}>
                  <p className="p-mini opacity-50 ">Name</p>
                  <p className="mb-1 fw-bold   text-truncate">{item.name ? item.name : '-'}</p>
                </div>

                <div className="ps-2 my-3 " style={{ width: '25%' }}>
                  <p className="p-mini opacity-50 ">Email</p>
                  <Tooltip placement="topLeft" title={item.email ? item.email : '-'}>
                    <p className="mb-1 fw-bold text-black text text-truncate">{item.email ? item.email : '-'}</p>
                  </Tooltip>
                </div>

                <div className="ps-6 my-3 " style={{ width: '10%' }}>
                  <p className="p-mini opacity-50 ">Status</p>
                  <div className="d-flex">
                    <p className="mb-1 ms-2 ps-1 fw-bold text-black ">
                      {item.status ? item.status === 'ACTIVE' ? <Badge color="green" /> : <Badge color="red" /> : ''}
                    </p>
                  </div>
                </div>

                <div className="ps-4 my-3 overflow-hidden " style={{ width: '23%' }}>
                  <p className="p-mini opacity-50 ">Sites</p>
                  <p className="mb-1 fw-bold text-black  text-truncate">{siteArr.join(', ')}</p>

                  {/*{siteLinkArr.map((site, siteIndex) => {
                    return (
                      <Link key={siteIndex} to={site.to} className="text-primary fw-bold me-1 ">
                        {site.siteNumber},
                      </Link>
                    )
                  })}*/}
                  {/*<Link className="mb-1 fw-bold text-black  text-truncate">{siteNumberArr.join(', ')}</Link>*/}
                </div>

                <div className="d-flex justify-content-end me-3 " style={{ width: '22%' }}>
                  <Link to={'clinician/' + item._id + '/edit-sites'} className="">
                    <Button className="me-2">Update site access</Button>
                  </Link>
                  <Link to={'clinician/' + item._id + '/view'} className="me-2">
                    <Button type="primary">View</Button>
                  </Link>
                </div>
              </div>
            )
          })
        ) : (
          <div className="col-12 mt-5">
            <Empty description="There are no active clinicians associated with the study"></Empty>
          </div>
        )}

        {notAssigned.length > 0 ? (
          <>
            <div className="d-flex row">
              <h6 className="mt-5 my-1 ">Not assigned to a site</h6>
              <p className="p-small mb-5 ">Please assign a site to the newly created user to grant them full access</p>
            </div>

            {notAssigned.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-12 bg-white  d-flex align-items-center border-bottom"
                  style={{ boxShadow: '0px 4px 4px -2px rgba(0, 0, 0, 0.1)' }}
                >
                  <div className="ps-2 my-3 " style={{ width: '30%' }}>
                    <p className="p-mini opacity-50 ">Name</p>
                    <p className="mb-1 fw-bold   text-truncate">{item.name ? item.name : '-'}</p>
                  </div>

                  <div className="ps-2 my-3 " style={{ width: '25%' }}>
                    <p className="p-mini opacity-50 ">Email</p>
                    <p className="mb-1 fw-bold text-black text text-truncate">{item.email ? item.email : '-'}</p>
                  </div>

                  <div className="ps-2 my-3 " style={{ width: '10%' }}>
                    <p className="p-mini opacity-50 ">Status</p>
                    <div className="d-flex">
                      <p className="mb-1 ms-2 ps-1 fw-bold text-black ">
                        {item.status ? item.status === 'ACTIVE' ? <Badge color="green" /> : <Badge color="red" /> : ''}
                      </p>
                    </div>
                  </div>

                  <div className="ps-2 my-3 " style={{ width: '15%' }}>
                    <p className="p-mini opacity-50 ">Title</p>
                    <p className="mb-1 fw-bold text-black  text-truncate">{item.jobTitle ? item.jobTitle : '-'}</p>
                  </div>

                  <div className="d-flex justify-content-end me-3 " style={{ width: '20%' }}>
                    <Link to={'clinician/' + item._id + '/edit-sites'} className="">
                      <Button className="me-2">Update site access</Button>
                    </Link>
                    <Link to={'clinician/' + item._id} className="">
                      <Button>View</Button>
                    </Link>
                  </div>
                </div>
              )
            })}
          </>
        ) : null}
      </div>
    </div>
  )
}
