import { getReq } from '../service/API'
import useSWR from 'swr'
import { ENDPOINTS } from '../service/ENDPOINTS'

export const useEligibility = (projectId: string, subjectId: string, prevent: boolean = false) => {
  const p = !prevent ? ENDPOINTS.SUBJECTS.eligibility(projectId, subjectId) : null

  const { data, error, mutate } = useSWR(p, getReq, {
    //suspense: true,
    shouldRetryOnError: false,
  })

  return {
    data: !data ? data : data.data,
    isLoading: !error && !data,
    isError: error,
    mutate: mutate,
  }
}
