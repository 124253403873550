import { PORTAL_API_URL } from '../constants'

export const ENDPOINTS = {
  GLOBAL: {
    logout: PORTAL_API_URL + '/user/logout',
  },

  PROJECTS: {
    GET_ME: (projectId) => {
      // notice this is a query variable used like ?projectId=1235
      return PORTAL_API_URL + `/user/?projectId=${projectId}`
    },
    TRAINING: {
      complete: (projectId: string) => {
        return PORTAL_API_URL + `/user/action/training`
      },
    },

    PROJECTS: PORTAL_API_URL + `/projects`,

    GET_PROJECT_BY_ID: (projectId) => {
      return PORTAL_API_URL + `/projects/${projectId}`
    },

    CONFIG: PORTAL_API_URL + `/projects/config`,
  },

  PROTOCOLS: {
    byProjectId: (projectId) => {
      return PORTAL_API_URL + `/projects/${projectId}/protocols`
    },
  },

  QUESTIONNAIRES: {
    byQuestionnaireId: (projectId: string, questionnaireId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/questionnaires/${questionnaireId}`
    },
  },

  PHASES: {
    GET: (projectId) => {
      return PORTAL_API_URL + `/projects/${projectId}/phases`
    },

    GET_PHASE_BY_ID: (phaseId) => {
      return PORTAL_API_URL + `/phaseId/${phaseId}`
    },
  },

  CLINICIANS: {
    //
    // return a list of clinicians for a project
    //
    byProjectId: (projectId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/clinicians` // + projectId
    },

    byClinicianId: (projectId: string, clinicianId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/clinicians/` + clinicianId
    },

    ACTION: {
      clinician: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/clinicians/action`
      },
      pii: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/clinicians/action/pii`
      },
      sites: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/clinicians/action/sites`
      },
    },

    logins: (projectId: string, clinicianId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/clinicians/${clinicianId}/logins`
    },

    actions: (projectId: string, clinicianId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/clinicians/${clinicianId}/actions`
    },
  },

  CRA: {
    byProjectId: (projectId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/cras`
    },
  },

  DM: {
    byProjectId: (projectId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/dms`
    },
  },
  CTM: {
    byProjectId: (projectId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/ctms`
    },
  },

  SITES: {
    byProjectId: (projectId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/sites`
    },

    bySiteId: (projectId: string, siteId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/sites/${siteId}`
    },

    get: (projectId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/sites`
    },

    getStaffForSite: (projectId: string, siteId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/sites/${siteId}/staff`
    },

    getUserSites: (projectId: string) => {
      return PORTAL_API_URL + `/user/${projectId}/sites`
    },
  },

  SUBJECTS: {
    ACTION: {
      create: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/action/create`
      },
      move: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/action/move-next`
      },
      moveEligibility: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/action/move-eligibility`
      },
      movePrevious: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/action/move-previous`
      },
      disqualify: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/action/disqualify`
      },
      requalify: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/action/requalify`
      },
      complete: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/action/complete`
      },
      uncomplete: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/action/uncomplete`
      },
      updatePii: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/action/update`
      },
      registerIMP: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/action/register-imp`
      },
    },
    eligibility: (projectId: string, subjectId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/` + subjectId + `/eligibility`
    },
    eligibleAssignments: (projectId: string, subjectId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/` + subjectId + `/eligible-assignments`
    },

    useGroups: (projectId: string, subjectId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/` + subjectId + `/groups`
    },

    attention: (projectId: string, siteId: string | null = null) => {
      if (siteId) {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/site/${siteId}/attention`
      } else {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/attention`
      }
    },

    get: (projectId, siteId: string | null = null) => {
      if (siteId) {
        return PORTAL_API_URL + `/projects/${projectId}/subjects/site/${siteId}`
      } else {
        return PORTAL_API_URL + `/projects/${projectId}/subjects`
      }
    },

    exportAllAssignments: (projectId) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/data/export/assignments-all`
    },

    exportSelectedAssignments: (projectId, siteIds: string[] | [] = []) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/data/export/assignments-selected?siteIds=${siteIds}`
    },

    // exportSubjects: (projectId, siteIds: string[] | [] = []) => {
    //   return PORTAL_API_URL + `/projects/${projectId}/subjects/data/export?siteIds=${siteIds}`
    // },

    exportSubjectAudits: (projectId, siteIds: string[] | [] = []) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/data/export/audits?siteIds=${siteIds}`
    },

    exportSubjectEligibility: (projectId, siteIds: string[] | [] = []) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/data/export/eligibility?siteIds=${siteIds}`
    },

    exportAbort: (projectId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/data/export/abort`
    },

    qr: (projectId, subjectId) => {
      //return PORTAL_API_URL + '/subjects/visits/events' + subjectId + '/?projectId=' + projectId
      return PORTAL_API_URL + `/projects/${projectId}/subjects/qr/` + subjectId + '/qr-code'
    },

    wipeDevice: (projectId) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/action/wipe`
    },

    disableDevice: (projectId) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/action/update-device`
    },

    enrollNewDevice: (projectId) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/action/enroll-new-device`
    },

    getSubjectVisitEvent: (projectId) => {
      //return PORTAL_API_URL + '/subjects/visits/events' + subjectId + '/?projectId=' + projectId
      return PORTAL_API_URL + `/projects/${projectId}/subjects/visits/events`
    },
    getSubjectAssignments: (projectId, subjectId) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/${subjectId}/assignments`
    },

    getSubjectPii: (projectId, subjectId) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/` + subjectId + '/pii'
    },

    getSubject: (projectId, subjectId) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/` + subjectId
    },

    getSubjectValidateScreeningId: (projectId, screeningId) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/validate/` + screeningId
    },

    getSubjectAudit: (projectId, subjectId) => {
      return PORTAL_API_URL + `/projects/${projectId}/subjects/` + subjectId + '/audit'
    },
  },

  ASSIGNMENT: {
    getAssignmentForSubject: (projectId, assignmentId, subjectId) => {
      return PORTAL_API_URL + `/projects/${projectId}/assignments/${assignmentId}/subjects/${subjectId}`
    },
  },

  VISITS: {
    ACTION: {
      activate: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/visits/action/activate`
      },
      complete: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/visits/action/complete`
      },
    },
  },

  TRIAL_STAFF: {
    byTrialStaffId: (projectId: string, staffId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/trial-staff/` + staffId
    },

    ACTION: {
      create: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/trial-staff/action`
      },
      sendInvite: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/trial-staff/action/send`
      },
      reSendInvite: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/trial-staff/action/resend`
      },
      updatePii: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/trial-staff/action/pii`
      },
      updateSites: (projectId: string) => {
        return PORTAL_API_URL + `/projects/${projectId}/trial-staff/action/sites`
      },
    },
    get: (projectId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/trial-staff`
    },

    logins: (projectId: string, staffId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/trial-staff/${staffId}/logins`
    },

    actions: (projectId: string, staffId: string) => {
      return PORTAL_API_URL + `/projects/${projectId}/trial-staff/${staffId}/actions`
    },
  },
} as const

/*

//
// extracted to be able to mock and validated type
//
const getUserType = () => {
  const userType = meStore.getState().me?.type.toLowerCase()
  if (!userType) {
    throw Error('User type is not defined in meStore.')
  }

  return 'ctm'
}
*/
