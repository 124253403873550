/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/03/2023.
 */
import { Suspense } from 'react'
import { Route, Routes, useParams } from 'react-router-dom'
import { PageHeader } from '../../components/display/PageHeader'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useSubjects } from '../../hooks/useSubjects'
import { SubjectCreatePage } from '../subjectCreate/SubjectCreatePage'
import { SubjectList } from './SubjectList'
import { SubjectView } from './SubjectView'

export const SubjectTrialstaffPage = () => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const { data } = useSubjects(projectId, siteId)

  return (
    <>
      <Routes>
        <Route
          path={'/view/:subjectId/*'}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <SubjectView></SubjectView>{' '}
            </Suspense>
          }
        />
        {/*SUBJECT LIST*/}
        <Route
          path={'/*'}
          element={
            <>
              <SubjectList
                header={
                  <PageHeader
                    title="Subject Overview"
                    body="View study participants in the Subject Overview. 
                  Review all participants and their progress, and explore individual profiles for details. Utilise tabs to filter by statuses and export subject data for analysis."
                  ></PageHeader>
                }
                subjectList={data.subjects}
              ></SubjectList>
            </>
          }
        />

        {/* -----
        SUBJECT CREATE
        ------ */}
        <Route
          path="/create"
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <SubjectCreatePage></SubjectCreatePage>
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}
