/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/03/2023.
 */
import { Drawer } from 'antd'
import React, { ComponentType, Suspense, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SpinnerFullHeight } from '../spinner/SpinnerFullHeight'
import { FallbackProps } from 'react-error-boundary/dist/declarations/src/types'
import { ErrorBoundary } from 'react-error-boundary'

type Props = {
  projectId: string
  title: string
  children?: any
  width?: number | string
  setVisible?: (value: boolean) => void
  to?: string | null
  header?: any
  errorView?: ComponentType<FallbackProps> | null
}

export const DrawerContainer = ({
  projectId,
  children,
  setVisible,
  width = '40%',
  title = 'Title ',
  to = null,
  header,
  errorView,
}: Props) => {
  //const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const [state, setState] = useState<boolean>(true)

  const updateVisible = (value: boolean) => {
    if (setVisible) {
      setVisible(true)
    }
  }

  const onClose = () => {
    //setVisible(false)
    setState(false)
  }

  const layoutChange = (e) => {
    // wtf moment
    const toPath = to === null ? '/project/' + projectId : to

    if (!e) {
      updateVisible(false)
      navigate(toPath)
    }
  }

  return (
    <Drawer
      afterOpenChange={layoutChange}
      title={title}
      destroyOnClose={true}
      width={width}
      placement="right"
      onClose={onClose}
      open={state}
      extra={header}
    >
      {/*{children}*/}

      {errorView ? (
        <ErrorBoundary FallbackComponent={errorView}>
          <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
            {React.cloneElement(children, { onClose: onClose })}
          </Suspense>
        </ErrorBoundary>
      ) : (
        <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
          {React.cloneElement(children, { onClose: onClose })}
        </Suspense>
      )}
    </Drawer>
  )
}
