import { Steps } from 'antd'
import { format } from 'date-fns'
import { SUBJECT_STATUS_ENUM } from '../../../Types'

export const StepsComponent = ({ phasesData, subjectData }) => {
  const getCurrentStep = (phasesData, subjectData) => {
    const phaseIds = phasesData.map((phase) => phase._id)
    const currentPhaseId = subjectData.currentPhase._id

    const onboardingStep = 0
    const completedStep = phaseIds.length + 1 // Last step

    // If the current phase ID matches the onboarding phase ID, return onboardingStep
    if (currentPhaseId === onboardingStep) {
      return onboardingStep
    }

    // Find the index of the currentPhaseId in the phaseIds array
    const currentStep = phaseIds.indexOf(currentPhaseId) + 1

    // If the current phase ID is not found in phaseIds, use completedStep
    if (currentStep === -1) {
      return completedStep
    }

    // Otherwise, return the currentStep for a regular phase
    return currentStep
  }

  const buildSteps = (phasesData, subjectData) => {
    const setupDate = format(new Date(subjectData.createdAt), 'd MMM yyyy')

    const onboardingStep = { title: 'Initiation', description: setupDate }

    const phasesSteps = phasesData.map((phase) => {
      return { title: phase.title, description: 'For ' + phase.duration + ' days' }
    })

    const completedStep = { title: 'Completed', description: '' }

    return [onboardingStep, ...phasesSteps, completedStep]
  }

  return (
    <>
      <Steps
        progressDot
        className="mb-2"
        direction="vertical"
        status={subjectData.status === SUBJECT_STATUS_ENUM.TERMINATED ? 'error' : 'process'}
        current={
          subjectData.status === SUBJECT_STATUS_ENUM.COMPLETED
            ? getCurrentStep(phasesData, subjectData) + 1
            : getCurrentStep(phasesData, subjectData)
        }
        items={buildSteps(phasesData, subjectData)}
      />
    </>
  )
}
