import { Alert, Button, DatePicker, DatePickerProps, Modal } from 'antd'
import { TimePicker, TimePickerProps } from 'antd/lib'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { post } from '../../../service/API'
import { ENDPOINTS } from '../../../service/ENDPOINTS'
import { endOfToday, isAfter, isSameDay } from 'date-fns'

export const SubjectIMPWrapper = () => {}

export const SubjectIMPModal = ({ isIMPOpen, setIMPOpen, mutate }) => {
  const { projectId, subjectId, groupId } = useParams() as { projectId: string; subjectId: string; groupId: string }

  const navigate = useNavigate()

  const [impDate, setIMPDate] = useState<any>()
  const [impTime, setIMPTime] = useState<any>()

  const [errorState, setErrorState] = useState<any>()

  useEffect(() => {
    setIMPDate(dayjs().format('DD-MM-YYYY'))
    setIMPTime(dayjs().format('HH:mm'))
  }, [setIMPDate, setIMPTime])

  const handleOk = () => {
    const sendData = {
      projectId: projectId,
      subjectId: subjectId,
      groupId: groupId,
      impDate: impDate,
      impTime: impTime,
    }

    if (validateDateAndTime(impDate, impTime).isValid) {
      setErrorState(undefined)
      return post(ENDPOINTS.SUBJECTS.ACTION.registerIMP(projectId), sendData, 'PUT')
        .then(() => {
          setErrorState(undefined)
          setIMPOpen(false)
          mutate()
        })
        .catch((error) => {
          setErrorState(error.message)
        })
    } else {
      setErrorState(validateDateAndTime(impDate, impTime))
    }
  }

  const handleCancel = () => {
    setIMPOpen(false)
  }

  const layoutChange = (e) => {
    // wtf moment
    const toPath = '../'

    if (!e) {
      navigate(toPath)
    }
  }

  const validateDateAndTime = (impDate, impTime) => {
    const isDateValid = dayjs(impDate, 'DD-MM-YYYY', true).isValid()
    const isTimeValid = dayjs(impTime, 'HH:mm', true).isValid()

    if (!isDateValid) {
      return { isValid: false, message: 'Imp application date not provided or invalid' }
    }

    if (!isTimeValid) {
      return { isValid: false, message: 'Imp application time not provided or invalid' }
    }

    return { isValid: true }
  }

  const handleDate: DatePickerProps['onChange'] = (date, dateString) => {
    setIMPDate(dateString)
  }

  const handleTime: TimePickerProps['onChange'] = (time, timeString) => {
    setIMPTime(timeString)
  }

  const disableFutureDates = (current) => {
    // Disable all dates after today
    return current && isAfter(current.toDate(), endOfToday())
  }

  const disableFutureTimesToday = (date) => {
    const now = dayjs()

    if (!isSameDay(date.toDate(), now.toDate())) {
      return {} // If it's not today, no need to disable any times
    }

    // If it is today, return the hours, minutes, and seconds that should be disabled
    return {
      disabledHours: () => Array.from({ length: 24 }, (_, i) => i).filter((hour) => hour > now.hour()),
      disabledMinutes: (selectedHour) =>
        selectedHour === now.hour()
          ? Array.from({ length: 60 }, (_, i) => i).filter((minute) => minute > now.minute())
          : [],
      disabledSeconds: (selectedHour, selectedMinute) =>
        selectedHour === now.hour() && selectedMinute === now.minute()
          ? Array.from({ length: 60 }, (_, i) => i).filter((second) => second > now.second())
          : [],
    }
  }

  return (
    <>
      <Modal
        title={[<div key={1}>Confirm IMP application time</div>]}
        open={isIMPOpen}
        onCancel={handleCancel}
        destroyOnClose={true}
        width={600}
        afterOpenChange={layoutChange}
        footer={<></>}
        maskClosable={false}
      >
        <p className="mb-3">
          Please confirm the time of IMP application for the subject. Following confirmation, the subject will receive
          questionnaires to report their pain levels post-application using the 11-point pain Numeric Rating Scale (NRS)
          as per the protocol.
        </p>

        <div className="">
          <div className="row">
            <div className="col-12">
              <div className=" justify-content-start">
                <div className="d-flex justify-content-center mb-2">
                  <p className="mt-1 me-2">Please select exact date and time:</p>
                </div>

                <div className="d-flex justify-content-center">
                  <DatePicker
                    defaultValue={dayjs()}
                    needConfirm={true}
                    allowClear={true}
                    format="DD-MM-YYYY"
                    onChange={handleDate}
                    className="me-2"
                    disabledDate={disableFutureDates}
                    showNow={false}
                  />
                  <TimePicker
                    defaultValue={dayjs()}
                    format={'HH:mm'}
                    onChange={handleTime}
                    needConfirm={true}
                    disabledTime={disableFutureTimesToday}
                    showNow={false}
                  />
                </div>
              </div>
              {errorState ? (
                <div className="mt-3">
                  <Alert showIcon description={errorState.message} type="error" />
                </div>
              ) : (
                <></>
              )}

              <div className="d-flex justify-content-center mt-3">
                <Button type="primary" onClick={handleOk}>
                  Confirm application
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
